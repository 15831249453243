import React from 'react'
import { title } from '../config/Config'
import { Link } from 'react-router-dom'
import { Facebook, Instagram, LinkedIn, Telegram } from '@mui/icons-material'
// import { Col, Row } from 'react-bootstrap'
import XIcon from '@mui/icons-material/X';
import logo_3d from "../Assets/new_images/logo_3d.png"
import Footer_bg from "../Assets/new-theme/footer-bg.png"
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';


const HomeFooter = () => {

    const auth = localStorage.getItem('auth');

    return (
        <ParallaxProvider>
            <div className='Home_footer'>
                <div className='__overly_footer'>
                    <ParallaxBanner layers={[{ image: Footer_bg, speed: 25 }]} className="aspect-[2/1] aimajs" />

                </div>
                <div className="container-index">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="heading">
                                {/* <h1>STA</h1>
                                <span>Green World</span> */}
                                <img src={logo_3d} alt="" />
                                <div className="">

                                    <p>{title} is a groundbreaking initiative focused on reducing carbon emissions and addressing climate change By utilizing blockchain technology. </p>
                                    <div className="links_bottom">
                                        <ul>
                                            <li><Link className='social_media' to="https://www.facebook.com/profile.php?id=61564525963913" target='_blank'><Facebook /></Link></li>
                                            <li><Link className='social_media' to="https://www.instagram.com/thegreenworldcommunity?igsh=aGNodjh3NTd6dmFz" target='_blank'><Instagram /></Link></li>
                                            <li><Link className='social_media' to="https://www.linkedin.com/company/tgw-the-green-world/" target='_blank'><LinkedIn /></Link></li>
                                            <li><Link className='social_media' to="https://x.com/GreenTgw97676" target='_blank'><XIcon /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                        <div className="links">
                            <h3>Service</h3>
                            <ul>
                                <li><Link className='page_links' to="/">Home</Link></li>
                                <li><Link className='page_links' to="/about">About</Link></li>
                                <li><Link className='page_links' to="/token">Token</Link></li>
                                <li><Link className='page_links' to="/roadmap">Roadmap</Link></li>
                            </ul>

                        </div>
                    </div> */}
                        <div className="col-md-6 col-lg-4 offset-lg-2">
                            <div className="input_side">
                                <h3>Connecting</h3>

                                {auth ?
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <button type='button' className='button_style ml-auto'>Wallet is Connected</button>
                                    </div>
                                    :
                                    <div className="input_field">
                                        <input type="text" placeholder='Wallet Address' />
                                        <Link to='/login'>Connect wallet</Link>
                                    </div>

                                }

                            </div>
                        </div>


                    </div>
                    {/* <div className="links_bottom">
                    <ul>
                        <li><Link className='social_media' to="/"><Facebook /></Link></li>
                        <li><Link className='social_media' to="/"><Instagram /></Link></li>
                        <li><Link className='social_media' to="/"><Telegram /></Link></li>
                        <li><Link className='social_media' to="/"><XIcon /></Link></li>
                    </ul>
                </div> */}
                    <div className='bottom_ca'>
                        <div className='four-index-container'>
                            <p>Copyrights © {(new Date().getFullYear())} All rights reserved {title}.</p>
                            <ul>
                                <li><Link className='page_links' to="/">Home</Link></li>
                                <li><Link className='page_links' to="/about">About</Link></li>
                                <li><Link className='page_links' to="/token">Token</Link></li>
                                <li><Link className='page_links' to="/roadmap">Roadmap</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ParallaxProvider>
    )
}

export default HomeFooter
