import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import { Table } from 'react-bootstrap';
import _fetch from '../../config/api';
import dateformat from "dateformat";
import Modal from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import NoResult from './NoResult';
import SupportTab from '../../Private/SupportTab';

const TicketView = (props: any) => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('');


    const onOpenModal = (image: any) => {
        setImage(image);
        setOpen(true);
    }
    const onCloseModal = () => setOpen(false);

    const [ticketHistory, setTicketHistory] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setpage] = useState(1);

    useEffect(() => {
        getTicketHistory();
    }, [page]);

    const getTicketHistory = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}support/ticket-history?limit=10&page=${page}`, "GET", {})
        if (res?.status === "success") {
            setLoading(false);
            setTicketHistory(res?.data);
            setTotalRecords(res?.total);
        }
    }

    return (
        <main>

            <SupportTab links={[{ name: 'create ticket', path: '/create-ticket' }, { name: 'ticket history', path: '/ticket-history' }]} />

            <div className="ticket_view">
                <div className="ticket_inner">
                    <div className="table_section ">

                        <div className="scrolling_table">
                            <div className="scroll_under">
                                <div className="table_responsive">
                                    <Table className="support_table all_tables">
                                        <thead className='thead_dashboard'>
                                            <tr className="table_heading_row">
                                                <th>{t("Subject")}</th>
                                                <th>{t("Description")}</th>
                                                <th>{t("Image")}</th>
                                                <th>{t("Date")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody_dashboard'>
                                            {ticketHistory.length == 0 ? <tr className='no_data_tr'>
                                                <td colSpan={4}>
                                                    <NoResult />
                                                </td>
                                            </tr> :
                                                <>
                                                    {
                                                        ticketHistory?.map((item: any, index: any) => {
                                                            return (
                                                                <>
                                                                    <tr className="table_data_row">
                                                                        <td className="table_data">
                                                                            <div className="pic_text">{item?.subject}</div>
                                                                        </td>
                                                                        <td className="table_data">{item?.description}</td>
                                                                        <td className="table_data payment_fee">
                                                                            <img src={item.image} onClick={() => onOpenModal(item.image)} alt={item?.subject + "img"} />
                                                                        </td>
                                                                        <td className="table_data"> {dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")} </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* image modal */}
            <Modal open={open} onClose={onCloseModal} center
                classNames={{
                    modal: "image_modal",
                }}>
                <img src={image} alt="ticket__img" />

            </Modal>
        </main>
    )
}

export default TicketView