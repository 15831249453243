import React, { useEffect, useState } from "react";
import { shortLogo, shortTitle, title } from "../config/Config";
import { Button, Dropdown, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Menu } from "@mui/icons-material";


const Header = () => {

    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    const [show, setShow] = useState(false);
    var auth = localStorage.getItem("auth");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    const HeaderData = [
        {
            LinkName: "Home",
            LinkUrl: '/',
            dropdown: null
        },
        {
            LinkName: "About",
            LinkUrl: '/about',
            dropdown: null
        },
        {
            LinkName: "Token",
            LinkUrl: '/token',
            dropdown: null,
        },
        {
            LinkName: "Roadmap",
            LinkUrl: '/roadmap',
            dropdown: null
        },

    ];



    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 150 && !isHeaderFixed) {
                setIsHeaderFixed(true);
            } else if (scrollPosition <= 150 && isHeaderFixed) {
                setIsHeaderFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isHeaderFixed]);



    return (
        <>


            <div className={"index-header" + `${isHeaderFixed ? ' fixed_' : ''}`}>
                <div className="container-index">
                    <div className="header-area">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="left-area-header">
                                <div className="header-logo">
                                    <Link to="/">
                                        <img src={shortLogo} alt={title + 'Logo'} />
                                    </Link>
                                </div>


                            </div>


                            <ul className="nav lg__size">
                                {HeaderData.map((item: any, index: any) => (

                                    <li className="nav-item" key={index}>
                                        {item.dropdown == null && <Link to={item.LinkUrl} className="nav-link">{item.LinkName} </Link>}

                                        {item.dropdown && (
                                            <ul className="p-0">
                                                <Dropdown>
                                                    <Dropdown.Toggle className="bg-transparent border-0 text-black nav-link" id="dropdown-basic">
                                                        {item.LinkName}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {item.dropdown.map((subItem: any) => (
                                                            <Link key={subItem.dropLinkName} className="dropdown-item" to={subItem.dropLinkUrl}>{subItem.dropLinkName} </Link>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <ul className="lg__size">
                            <li className="nav-item btn_item">
                                <div className="for-position">
                                    <Link className="nav-swap" target="_blank" to="https://pancakeswap.finance/swap?inputCurrency=0x80C260cd80d8a2855b554A7d5001BD06421a6fd4&outputCurrency=0x55d398326f99059fF775485246999027B3197955">
                                        <img src="https://avatars.githubusercontent.com/u/71247426?s=200&v=4" alt="swap" />

                                    </Link>
                                    <div className="n-ppost-name">
                                        <h3>Buy {shortTitle}</h3>
                                        <p> Steps to Buy {shortTitle} Tokens on PancakeSwap</p>
                                    </div>
                                </div>
                                {/* <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                                </OverlayTrigger> */}

                                {auth ?
                                    <Link className="nav-button" to="/dashboard"> Dashboard</Link>
                                    :
                                    <>
                                        <Link className="nav-button" to="/login"> Login</Link>
                                        <Link className="nav-button" to="/register"> Sign Up</Link>
                                    </>
                                }
                                <Button onClick={handleShow} className="toggle_btn"> <Menu /> </Button>
                            </li>
                        </ul>


                        {/* this phone view dropdown */}
                        <Offcanvas placement={'end'} show={show} onHide={handleClose}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                    <img src={shortLogo} alt={title + 'Logo'} width="60px" />
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <ul className="phone-View">
                                    {HeaderData.map((item: any, index: any) => (

                                        <li className="nav-item" key={index}>
                                            {item.dropdown == null && <Link to={item.LinkUrl} onClick={handleClose} className="nav-link">{item.LinkName} </Link>}

                                            {item.dropdown && (
                                                <ul className="p-0">
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="bg-transparent border-0 text-black nav-link" id="dropdown-basic">
                                                            {item.LinkName}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {item.dropdown.map((subItem: any) => (
                                                                <Link key={subItem.dropLinkName} className="dropdown-item" to={subItem.dropLinkUrl}>{subItem.dropLinkName} </Link>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </ul>
                                            )}
                                        </li>
                                    ))}

                                    <li className="nav-item nav-item_btn">

                                        {auth ?
                                            <Link className="nav-button" to="/dashboard"> Dashboard</Link>
                                            :
                                            <>
                                                <Link className="nav-button" to="/login"> Login</Link>
                                                <Link className="nav-button" to="/register"> Sign Up</Link>
                                            </>
                                        }
                                    </li>
                                </ul>
                            </Offcanvas.Body>
                        </Offcanvas>

                    </div>

                </div>

            </div>

        </>
    );
};

export default Header;