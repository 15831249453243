import React, { useEffect, useState } from "react";
import { api_url, incomesList, titleDescription } from "../../config/Config";
import { Table } from "react-bootstrap";
import _fetch from "../../config/api";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import NoResult from "./NoResult";
import { Link, useParams } from "react-router-dom";

const DirectActivationHistory = (props: any) => {
  const { t } = useTranslation();
 

  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(1);
  const [allIncomes, setAllIncomes] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [incomeChange, setIncomeChange] = useState('');
  const {username} = useParams();
  useEffect(() => {
    getAllIncomeReport();
    document.title = `${props.pageTitle} | ${titleDescription}`;

  }, [incomeChange, page, totalRecord]);


  const getAllIncomeReport = async () => {
    setLoading(true);

    let res = await _fetch(`${api_url}report/team-activation-history?username=${username}&limit=10&page=${page}`, "GET", {})

    if (res?.status === "success") {
      setLoading(false);
      setAllIncomes(res?.data?.data);
      setTotalRecord(res?.total);
    }

  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };
  return (
    <main>

      <div className="uni-level-outer">
        <div className="level_inner common_card_css loader_main">
          <div className='sub-header-kt sub-header-flex'>
            <div className="led-selected">
              <h4>Direct Activation/Upgrade Ledger</h4>
              {/* <form className="ledger-form">
                <select className="form-control ledger" onChange={(e: any) => { setIncomeChange(e.target.value); setpage(1) }}>
                  <option value="">All Select Type</option>
                  {incomesList?.map((income: any) => {
                    return (
                      <option value={income?.type}>{t(income?.name)}</option>

                    );
                  })}
                  <option value="withdraw_request">{t("Withdraw")}</option>
                </select>
              </form> */}
            </div>
          </div>
          <div className="table_section mt-4">
            <div className="scrolling_table">
              <div className="scroll_under">
                <div className="table_responsive">
                  <Table className="support_table all_tables">
                    <thead className='thead_dashboard'>
                      <tr className="table_heading_row">
                        <th>{t("Activation Date")}</th>
                        <th>{t("Package")}</th>
                        <th>{t("Price")}</th>
                        <th>{t("Token Staked ")}</th>
                        <th>{t("Bonus Token")}</th>

                        <th>{t("Type")}</th>
                        <th>{t("Hash")}</th>


                        
                      </tr>
                    </thead>
                    <tbody className='tbody_dashboard'>
                      {allIncomes?.length == 0 ? <tr className='no_data_tr'>
                        <td colSpan={7}>
                          <NoResult />
                        </td>
                      </tr> :

                        <>
                          {allIncomes?.map((item: any, index: any) => {
                            return (
                              <>

                                <tr className="table_data_row" key={index}>
                                  <td className="table_data">
                                    <div className="pic_text">{dateFormat(item?.activate_date, "UTC:dd-mm-yyyy hh:mm:ss TT")}</div>
                                  </td>
                                  <td className="table_data"> <span className={'text-success'}>${item?.package?.toFixed(4)}</span></td>
                                  <td className="table_data"> {item?.price?.toFixed(4)}</td>
                                  <td className="table_data"> {item?.token > 0 ? (item?.token / 2)?.toFixed(4):'0'}</td>
                                  <td className="table_data"> {item?.token > 0 ? (item?.token / 2)?.toFixed(4):'0'}</td>

                                  <td className="table_data"> {item?.type}</td>
                                  <td className="table_data"><Link to={`https://bscscan.com/tx/${item?.hash}`} target="_blank" >{item?.hash ? item?.hash : '--'}</Link></td>




                                </tr>
                              </>
                            )
                          })}
                        </>
                      }

                    </tbody>
                  </Table>
                  {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={totalRecord / 10}
                    previousLabel="<"
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DirectActivationHistory;
