import { CopyAll, Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from '../../../config/Loader';
import toasted from '../../../config/toast';
import _fetch from '../../../config/api';
import { api_url } from '../../../config/Config';
import { useNavigate } from 'react-router-dom';

const Passcode = (props: any) => {
    const { t } = useTranslation();

    const [phrase, setPhrase] = useState<string[]>([]); // For the generated phrase
    const [phase1, setPhase1] = useState<string>(""); // State for Phase 1
    const [phase2, setPhase2] = useState<string>(""); // State for Phase 2
    const [phase3, setPhase3] = useState<string>(""); // State for Phase 3
    const [phase4, setPhase4] = useState<string>(""); // State for Phase 4
    const [phase5, setPhase5] = useState<string>(""); // State for Phase 5
    const [passcode, setpasscode] = useState<string>(""); // State for Phase 5
    const [confirmPasscode, setconfirmPasscode] = useState<string>(""); // State for Phase 5


    const [showPassword3, setShowPassword3] = useState(false);
    const [passwordBtnDisable, setPasswordBtnDisable] = useState(false);

    const handleTogglePassword3 = () => {
        setShowPassword3(!showPassword3);
    };

    useEffect(() => {
        checkPhaceVerifid();
    }, [])

    const getphrase = async () => {
        let res = await _fetch(`${api_url}phrase/generatePhrase`, "GET", {});
        if (res?.status === "success") {
            toasted.success('Phrase generated successfully!');
            setPhrase(res?.data?.phrase);
        } else {
            toasted.error(res?.message);

        }
    };

    const checkPhaceVerifid = async () => {
        let res = await _fetch(`${api_url}phrase/checkPhrase`, "GET", {});
        if (res?.status === "success") {
            setChangeClass(res?.data?.verify);
        } else {
            toasted.error(res?.message);

        }
    };

    const [changeClass, setChangeClass] = useState(false);

    const copyToClipboard = (address: any, message: any) => {
        var textField = document.createElement('textarea');
        textField.innerText = address;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toasted.success(message + ' copied successfully!');
        setChangeClass(true);
    };
    const Navigate = useNavigate();

    // Handle form submission
    const handleSubmit = async () => {
        const mergedPhases = [phase1, phase2, phase3, phase4, phase5];

        setPasswordBtnDisable(true);

        let data = {
            "phrase": mergedPhases,
            "passCode": passcode,
            "verifyPassCode": confirmPasscode
        }
        let result = await _fetch(`${api_url}phrase/passCodeRequest`, "POST", data, {})
        if (result?.status === "success") {
            toasted.success('Pass Code Set successfully!');
            setPasswordBtnDisable(false);
            Navigate("/dashboard");

        }
        else {
            toasted.error(result?.message);
            setPasswordBtnDisable(false);
        }
    };

    const [inputValue, setInputValue] = useState('');
    const [arrayValue, setArrayValue] = useState([]);

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text');
        const phases = paste.split(/\r?\n|\s*,\s*|\s+/); // Adjust the regex as needed
    
        console.log('Pasted text:', paste);
        console.log('Phases:', phases);
    
        if (phases.length === 5) {
            setPhase1(phases[0] || '');
            setPhase2(phases[1] || '');
            setPhase3(phases[2] || '');
            setPhase4(phases[3] || '');
            setPhase5(phases[4] || '');
        }
    };
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        
        // Set the appropriate phase state based on the input name
        switch (name) {
            case 'recovery-phrase-1':
                setPhase1(value);
                break;
            case 'recovery-phrase-2':
                setPhase2(value);
                break;
            case 'recovery-phrase-3':
                setPhase3(value);
                break;
            case 'recovery-phrase-4':
                setPhase4(value);
                break;
            case 'recovery-phrase-5':
                setPhase5(value);
                break;
            default:
                break;
        }
    };
    
    
  
    const handleInputChange = (e: any) => {
        const value = e.target.value;
        setInputValue(value);
        
        // Convert string to array
        const array = value
          .split(',')
          .map((item: string) => item.trim()) // TypeScript knows 'item' is a string
          .filter((item: string) => item.length > 0); // Filter out empty strings
        
        setArrayValue(array);
      };

    return (
        <div>
            <div className={changeClass === false ? "accout-seting password__settings" : "accout-seting password__settings d-none"}>
                {phrase.length > 0 && (<div className="default-block__title --border-bottom">{t("Generate five-word passphrase")} </div>)}
                <Row className="password__settings">
                    <div className="alert alert-warning" role="alert">
                        <h6 className="alert-heading text-center">Important: Save Your Security Passphrase</h6>
                        <p>
                            Please securely save the following five-word passphrase. This passphrase is essential for setting or changing your passcode and will be required for key actions like withdrawals and profile updates. Safeguard it carefully to ensure the security of your account.
                        </p>
                    </div>
                    {phrase.length > 0 && (
                        <Col lg="12">
                            <label className="form-label d-block mb-2">Five-word passphrase </label>
                            <label className="form-label d-block mb-2"> Your TGW ID: {props?.username}</label>
                            <div className="border rounded p-3 bg-dark text-center">
                                <div className="d-flex flex-wrap justify-content-center mb-2">
                                    {phrase.map((val, index) => (
                                        <div key={index} className="d-inline-flex align-items-center me-2 mb-2">
                                            <span className="badge bg-secondary me-2">{index + 1}</span>
                                            <p className="mb-0 me-2">{val}</p>
                                        </div>
                                    ))}
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary w-auto mx-auto"
                                    onClick={() => copyToClipboard(phrase, 'Phrase')}
                                >
                                    <CopyAll /> Copy All
                                </button>
                            </div>

                        </Col>
                    )}
                    {phrase.length == 0 && (
                        <div className="d-flex align-items-center justify-content-center mt-3">
                            <button className="new_button_css_dashboard" onClick={getphrase}>
                                {passwordBtnDisable === false && `${t("Generate")} ${t("Phrase")}`}
                            </button>
                        </div>
                    )}
                </Row>
            </div>

            <div className={changeClass === true ? "account-setting password__settings" : "account-setting password__settings d-none"}>
                <div className="default-block__title border-bottom pb-2 mb-4">{t("Import Your Recovery Phrase and Set Up Passcode")}</div>
                <Row>
                <Col lg="6">
            <div className="mb-3">
                <label className="form-label">{t("Recovery Phrase 1")}</label>
                <input
                    type='text'
                    placeholder={t("Enter Recovery Phrase 1")}
                    className="form-control"
                    value={phase1}
                    onChange={handleChange}
                    onPaste={handlePaste}
                    autoComplete="off"
                    name="recovery-phrase-1"
                    aria-label={t("Recovery Phrase 1")}
                />
            </div>
        </Col>

        <Col lg="6">
            <div className="mb-3">
                <label className="form-label">{t("Recovery Phrase 2")}</label>
                <input
                    type='text'
                    placeholder={t("Enter Recovery Phrase 2")}
                    className="form-control"
                    value={phase2}
                    onChange={handleChange}
                    onPaste={handlePaste}
                    autoComplete="off"
                    name="recovery-phrase-2"
                    aria-label={t("Recovery Phrase 2")}
                />
            </div>
        </Col>

        <Col lg="6">
            <div className="mb-3">
                <label className="form-label">{t("Recovery Phrase 3")}</label>
                <input
                    type='text'
                    placeholder={t("Enter Recovery Phrase 3")}
                    className="form-control"
                    value={phase3}
                    onChange={handleChange}
                    onPaste={handlePaste}
                    autoComplete="off"
                    name="recovery-phrase-3"
                    aria-label={t("Recovery Phrase 3")}
                />
            </div>
        </Col>

        <Col lg="6">
            <div className="mb-3">
                <label className="form-label">{t("Recovery Phrase 4")}</label>
                <input
                    type='text'
                    placeholder={t("Enter Recovery Phrase 4")}
                    className="form-control"
                    value={phase4}
                    onChange={handleChange}
                    onPaste={handlePaste}
                    autoComplete="off"
                    name="recovery-phrase-4"
                    aria-label={t("Recovery Phrase 4")}
                />
            </div>
        </Col>

        <Col lg="6">
            <div className="mb-3">
                <label className="form-label">{t("Recovery Phrase 5")}</label>
                <input
                    type='text'
                    placeholder={t("Enter Recovery Phrase 5")}
                    className="form-control"
                    value={phase5}
                    onChange={handleChange}
                    onPaste={handlePaste}
                    autoComplete="off"
                    name="recovery-phrase-5"
                    aria-label={t("Recovery Phrase 5")}
                />
            </div>
        </Col>

                    <Col lg="12">
                        <div className="mb-3">
                            <label className="form-label">{t("Passcode")}</label>
                            <div className="position-relative">
                                <input
                                    type={showPassword3 ? 'number' : 'password'}
                                    placeholder={t("Enter Passcode  keys in 6 digits")}
                                    value={passcode}
                                    onChange={(e) => setpasscode(e.target.value)}
                                    className="form-control"
                                    autoComplete="off"
                                    name="passcode"
                                />
                                <button type="button" onClick={handleTogglePassword3} className="position-absolute end-0 top-50 translate-middle-y border-0 bg-transparent">
                                    {showPassword3 ? <Visibility /> : <VisibilityOff />}
                                </button>
                            </div>
                        </div>
                    </Col>

                    <Col lg="12">
                        <div className="mb-3">
                            <label className="form-label">{t("Confirm Passcode")}</label>
                            <div className="position-relative">
                                <input
                                    type={showPassword3 ? 'number' : 'password'}
                                    placeholder={t("Confirm Passcode  keys in 6 digits")}
                                    value={confirmPasscode}
                                    onChange={(e) => setconfirmPasscode(e.target.value)}
                                    className="form-control"
                                    autoComplete="off"
                                    name="confirm-passcode"
                                />
                                <button type="button" onClick={handleTogglePassword3} className="position-absolute end-0 top-50 translate-middle-y border-0 bg-transparent">
                                    {showPassword3 ? <Visibility /> : <VisibilityOff />}
                                </button>
                            </div>
                        </div>
                    </Col>

                    <Col lg="12">
                        <div className="d-flex justify-content-center mt-4">
                            <button className="btn btn-primary" onClick={handleSubmit} disabled={passwordBtnDisable}>
                                {passwordBtnDisable ? <Loader LoaderTxt={t('Please Wait...')} /> : `${t("Update")} ${t("Passcode")}`}
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    );
}

export default Passcode;
