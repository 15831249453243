import React, { useEffect, useState } from 'react';
import { InjectedConnector } from "@web3-react/injected-connector";
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import {
  contractAbi, contractAddress, supportedChainId, TGWContractAbi, TGWContractAddress
} from './Config';
import { useWeb3Con } from './Web3ContextProvider';
// import { useWeb3Con } from 'utils/Web3ContextProvider';

const Web3Set = (props) => {
  const { WalletAddress, setWalletAddress, setweb3data } = useWeb3Con();

  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [coinBalance, setCoinBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [disconnectStataus, setdisconnectStataus] = useState(false);
  const [tokenContract, setTokenContract] = useState(null);
  const [TGWContract, setTGWContract] = useState(null);
  const [ReTGWContract, setReTGWContract] = useState(null);
  const [WithdrawContract, setWithdrawContract] = useState(null);
  const [sellContract, setsellContract] = useState(null);
  const [leftCoinBalance, setleftCoinBalance] = useState(0);
  const [findweb3, setfindweb3] = useState(0);




  const connectWallet = async () => {
    console.log('sedfsfdsdfsd');
    setdisconnectStataus(false);
    console.log('web3web3', web3);
    if (web3 !== null) {
      try {
        const chainIdd = await web3.eth.getChainId();
        const chainId = chainIdd.toString().slice(0, -1);
        const hexChainId = web3.utils.toHex(chainId);
        const checkChain = supportedChainId.includes(hexChainId);
        if (!checkChain) {
          switchNetwork(supportedChainId[0]);
        }

        await web3.currentProvider.enable();
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          setWalletAddress(accounts[0])
          getCoinBalances(web3, accounts);
          getTokenBalances(web3, accounts);
        }
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    } else {
      setfindweb3(findweb3 == 0 ? 1 : 0);
    }
  };

  const switchNetwork = async (receiveChainId) => {
    if (web3) {
      try {
        await web3.currentProvider.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: receiveChainId.toString(),
            chainName: 'Binance Smart Chain',
            nativeCurrency: {
              name: 'BNB',
              symbol: 'BNB',
              decimals: 18,
            },
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            blockExplorerUrls: ['https://bscscan.com'],
          }],
        });
      } catch (error) {
        console.error('Error switching network:', error);
      }
    }
  };

  const getCoinBalances = async (web3Instance, accounts) => {
    const getCoinBalance = await web3Instance.eth.getBalance(accounts[0]);
    const formattedCoinBalance = parseFloat(web3Instance.utils.fromWei(getCoinBalance, 'ether'));
    setCoinBalance(formattedCoinBalance > 0 ? formattedCoinBalance : 0);

  }

  const getTokenBalances = async (web3Instance, accounts) => {
    const contract = new web3Instance.eth.Contract(contractAbi, contractAddress);
    setTokenContract(contract);
    props.settokenContract(contract)
    console.log('contract', contract);


    // const getTokenBalance = await contract.methods.balanceOf(accounts[0]).call();
    // const tokenDecimals = await contract.methods.decimals().call();
    // const formattedBalance = getTokenBalance / (10 ** parseInt(tokenDecimals));

    // Fetch the token balance and convert it to a number
    const getTokenBalance = await contract.methods.balanceOf(accounts[0]).call();
    // const balanceAsNumber = parseFloat(getTokenBalance);

    // Fetch the token decimals and convert it to a number
    // const tokenDecimals = parseInt(await contract.methods.decimals().call(), 10);

    // Calculate the formatted balance using regular numbers
    // const formattedBalance = parseInt(balanceAsNumber / (10 ** tokenDecimals));

    // Display the result
    // console.log(formattedBalance);
    const formattedBalance = parseFloat(web3Instance.utils.fromWei(getTokenBalance, 'ether'));
    console.log("getTokenBalance", getTokenBalance, "formattedBalance", formattedBalance);

    setTokenBalance(formattedBalance > 0 ? formattedBalance : 0);
    props.setusdtBalance(formattedBalance > 0 ? formattedBalance : 0);

    const tokenContractInstance = new web3Instance.eth.Contract(TGWContractAbi, TGWContractAddress);
    setTGWContract(tokenContractInstance);
    props.setTGWContract(tokenContractInstance)
    console.log('tokenContractInstance', tokenContractInstance);

    setfindweb3(2);
    // const RestaketokenContractInstance = new web3Instance.eth.Contract(ReTGWContractAbi, ReTGWContractAddress);
    // const _balance = await RestaketokenContractInstance.methods.balanceOf(TGWContractAddress).call();
    // const leftVista = _balance / (10 ** 18);
    // setleftCoinBalance(leftVista);
    // setReTGWContract(RestaketokenContractInstance);

    // const withdrawContractInstance = new web3Instance.eth.Contract(WithdrawContractAbi, WithdrawContractAddress);
    // setWithdrawContract(withdrawContractInstance);

    // const sellContractInstance = new web3Instance.eth.Contract(sellContractAbi, sellContractAddress);
    // setsellContract(sellContractInstance);
  }

  const passprops = {
    coinBalance: parseFloat(coinBalance).toFixed(3),
    tokenBalance: parseFloat(tokenBalance).toFixed(3),
    address: account,
    web3: web3,
    tokenContract: tokenContract,
    TGWContract: TGWContract,
    // ReTGWContract: ReTGWContract,
    // leftCoinBalance: leftCoinBalance,
    // WithdrawContract: WithdrawContract,
    // sellContract: sellContract,
    connectWallet: connectWallet
  };

  useEffect(() => {
    props.stateprop(passprops);
    setweb3data(passprops)

    // if (!disconnectStataus) {
    const loadWeb3 = async () => {
      const provider = await detectEthereumProvider() || new InjectedConnector({ supportedChainIds: supportedChainId });

      if (provider) {
        if (provider.isMetaMask) {
          provider.autoRefreshOnNetworkChange = false;
        }
        const web3Instance = new Web3(provider);
        setWeb3(web3Instance);
        props?.setweb3(web3Instance)

        provider.on('accountsChanged', (newAccounts) => {
          setAccount(newAccounts.length === 0 ? null : newAccounts[0]);
        });

        const accounts = await web3Instance.eth.getAccounts();
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          setWalletAddress(accounts[0])
          if (web3) {
            getTokenBalances(web3, accounts);
          }
        }
      }
    };
    loadWeb3();
    // }
    connectWallet()
    // setfindweb3(0)
  }, [account, disconnectStataus, findweb3]);

  const disconnectWallet = () => {
    localStorage.clear();
    setWeb3(null);
    setAccount(null);
    setdisconnectStataus(true);
  };
  // console.log(passprops);

  return (
    <div>


      {/* <button onClick={() => { connectWallet() }}>Connect</button><br></br><br></br><br></br><br></br><br></br><br></br> */}
    </div>
  );
}

export default Web3Set;
