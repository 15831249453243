import React, { useEffect } from 'react'
import { title, titleDescription } from '../../config/Config';
import { logo } from '../../config/Config';
import england from '../../Assets/Image/england.webp';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import SupportTab from '../../Private/SupportTab';
// import pdft from '../../Assets/new-theme/presentatio.pdf'



const Presentations = (props: any) => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });

    return (
        <main>

            {/* <SupportTab links={[{ name: 'Presentations', path: '/presentations' }]} /> */}

            <div className="presentation_main">

                <div className="present_inner">

                    <div className="row present_row">
                        <div className="col-12">
                            <div className="card present_card">
                                <div className="card-body">
                                    <div className="image_logo">
                                        <img src={logo} className="header-brand-img light-logo" alt="logo" />

                                        <p> {title} {t("Presentations")}</p>
                                    </div>

                                    {/* <div className="country_sec">
                                        <img src={england} className='logo_sec' alt="logo" />
                                        <h6>England</h6>
                                    </div> */}

                                    <a className='new_button_css_dashboard' href="../../../the_green_presentation.pdf"  download > <DownloadIcon /> <span>Download</span></a>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-12">
                            <div className="card present_card">
                                <div className="card-body">
                                    <div className="image_logo">
                                        <img src={logo} className="header-brand-img light-logo" alt="logo" />

                                        <p> {t("Presentations")}</p>
                                    </div>

                                    <div className="country_sec">
                                        <img src={england} className='logo_sec' alt="logo" />
                                        <h6>Singapore</h6>
                                    </div>

                                    <button className='new_button_css_dashboard' type='button'> <DownloadIcon /> <span>Download</span></button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Presentations