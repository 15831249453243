import React, { useEffect, useState } from "react";
import { api_url, bnbFee, receivingAddress, TGWContractAddress, title } from "../../config/Config";
import logo_3d from "../../Assets/new_images/logo_3d.png"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toasted from "../../config/toast";
import _fetch from "../../config/api";
import Loader from "../../config/Loader";
import Web3Set from "../../config/Web3Set";
import leavesTree from '../../Assets/new-theme/login-bg.png';
import leaves_single from '../../Assets/new-theme/leaves-single.png';



const FailedRegister = (props) => {
     const [gethash, setGethash] = useState('');

     const [approveLoader, setapproveLoader] = useState('0');
     const [changeSection, setchangeSection] = useState(false);
     const [activeLogin, setactiveLogin] = useState(false);
     const [coinPrice, setcoinPrice] = useState(0);
     const [getSopnserWallet, setgetSopnserWallet] = useState('');
     const [usdtBalance, setusdtBalance] = useState(0);

     const [offsetX, setOffsetX] = useState(0);
     const [offsetY, setOffsetY] = useState(0);
     const location = useLocation();

     const { sponser, userPosition } = useParams();

     const handleMouseMove = (e) => {
          const { clientX, clientY, currentTarget } = e;
          const { offsetWidth, offsetHeight } = currentTarget;

          const xPos = (clientX / offsetWidth) - 0.9; // -0.9 to 0.9
          const yPos = (clientY / offsetHeight) * 2; // -0.9 to 0.9

          setOffsetX(xPos * 35);
          setOffsetY(yPos * 30);
     };

     const [tokenContract, settokenContract] = useState();


     useEffect(() => {

          if (userPosition) {
               setposition(userPosition)
          }
          if (sponser) {
               setsponserId(sponser);
          }

          if (location.pathname === '/failed-register') {

               document.body.classList.add('--register');

          } else {

               document.body.classList.remove('--register');
          }
          getCSRF()
          return () => {
               document.body.classList.remove('--register');
          };
     }, [location.pathname, tokenContract]);

     const [csrfToken, setCsrfToken] = useState('');

     const getCSRF = async () => {
          await fetch(`${api_url}security/csrf-token`, { credentials: 'include' }) // Ensure cookies are sent
              .then(response => response.json())
              .then(data => setCsrfToken(data.csrfToken));
  
      }
     const [web3Details, setweb3Details] = useState({ web3: null, address: '' })
     const Navigate = useNavigate();
     const [btnDisable, setbtnDisable] = useState(false);
     const [sponserId, setsponserId] = useState('');
     const [position, setposition] = useState('L');
     const [tokenAmount, settokenAmount] = useState();
     const [web3, setweb3] = useState();
     const [TGWContract, setTGWContract] = useState();
     const [hash, sethash] = useState();


     const generateRandomAlphabet = () => {
          const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
          const randomIndex = Math.floor(Math.random() * alphabet.length);
          return (alphabet[randomIndex]);
     };

     const submitRegister = async (e) => {
          e.preventDefault()
          setapproveLoader(0);

          setLoading(true)
          let data = {
               "wallet_address": web3Details?.address,
               "sponsor_id": sponserId,
               "position": position,
               "amount": tokenAmount,
               'hash': hash,

          }
          let res = await _fetch(`${api_url}auth/signup`, "newPost", data, csrfToken)

          if (res?.status === 'success') {
               localStorage.setItem("auth", 'true');
               localStorage.setItem("accessToken", res?.token);
               localStorage.setItem("user", JSON.stringify(res?.data?.user));
               localStorage.setItem("userName", res?.data?.user?.username);

               localStorage.setItem("preview_id", 'false');
               toasted.success(res?.message);
               Navigate("/dashboard");
               setLoading(false)
          }
          else {
               localStorage.clear();
               toasted.error(res?.message);
               console.log(res?.message);
               setLoading(false)
               getCSRF();
          }

     }
     const [loading, setLoading] = useState(false);




     return (
          <div onMouseMove={handleMouseMove}>
               <main>
                    <Web3Set stateprop={setweb3Details} setweb3={setweb3} setusdtBalance={setusdtBalance} setTGWContract={setTGWContract} settokenContract={settokenContract} />

                    <div className="__register-page" >
                         <div className="__container">

                              <div className="leaves-single-gh">
                                   <img src={leaves_single} alt='icon' />

                              </div>

                              <div className="row align-items-center">
                                   <div className="col-lg-6">
                                        <div className="__left-register">
                                             <h2>Registration in <br /><span style={{ color: "#e8cc56" }}>{title}</span></h2>
                                             <h6>Your Balance is: {usdtBalance?.toFixed(4)} USDT</h6>
                                             {/* <h3 className='text-danger'>Important Notice !!!</h3>
                                             <p className='mb-0 '>New Registration/Upgrade and Withdrawal will be off  From 12:00 PM To 3:00 PM </p>
                                             <p className=''>The inconvenience caused is deeply regretted.</p> */}
                                             <form onSubmit={submitRegister}>
                                                  <input type="text" placeholder="Sponser ID" onChange={(e) => { setsponserId(e.target.value) }} value={sponserId} required />

                                                  <input type="text" placeholder="Wallet Address" value={web3Details?.address} readOnly required />


                                                  <input type="number" placeholder="Enter Amount" onChange={(e) => { settokenAmount(e.target.value) }} value={tokenAmount} required />
                                                  <input type="text" placeholder="Enter txn hash" onChange={(e) => { sethash(e.target.value) }} value={hash} required />


                                                  <div className="lists">
                                                       <label htmlFor="left_p">
                                                            <input type="radio" id="left_p" className="typs-inputs" name="type" value="L" checked={position === 'L'}
                                                             onChange={(e) => setposition(e.target.value)}
                                                             required /> Left
                                                       </label>

                                                       <label htmlFor="right_p" onChange={(e) => { setposition(e.target.value) }}>
                                                            <input type="radio" id="right_p" className="typs-inputs" name="type" value="R" checked={position === 'R'}
                                                            onChange={(e) => setposition(e.target.value)}
                                                            required /> Right
                                                       </label>
                                                  </div>

                                                  {/* <div className="__conditions">
                                                       <div className="checkbox-wrapper-24">
                                                            <input type="checkbox" id="check_1" name="check" value="" />
                                                            <label htmlFor="check_1"> <span> </span>sponser ID: sponser Not find </label>
                                                       </div>

                                                       <div className="checkbox-wrapper-24">
                                                            <input type="checkbox" id="check_3" name="check" value="" />
                                                            <label htmlFor="check_3"> <span> </span>Network: </label>
                                                       </div>

                                                       <div className="checkbox-wrapper-24">
                                                            <input type="checkbox" id="check_4" name="check" value="" />
                                                            <label htmlFor="check_4"> <span> </span>Registration: not available </label>
                                                       </div>

                                                       <div className="checkbox-wrapper-24">
                                                            <input type="checkbox" id="check_5" name="check" value="" />
                                                            <label htmlFor="check_5"> <span> </span>Balance: min - </label>
                                                       </div>

                                                       <div className="checkbox-wrapper-24">
                                                            <input type="checkbox" id="check_6" name="check" value="" />
                                                            <label htmlFor="check_6"> <span> </span>Approve {title.toLocaleLowerCase()}: Required </label>
                                                       </div>
                                                  </div> */}


                                                  <div className="agree_terms">
                                                  <div><input type="checkbox" className="mb-0" id="conditions" required /></div>
                                                  <label htmlFor="conditions"><div>I accept the terms and conditions.</div></label>
                                                  </div>



                                                  <button type="submit" className="submit-btn btn" disabled={loading}>
                                                       {approveLoader == 0 ? 'Register Now' : approveLoader == 1 ? <Loader LoaderTxt={' Waiting for approval!'} /> : <Loader LoaderTxt={' Waiting for confirmation'} />}

                                                  </button>
                                             </form>
                                        </div>
                                   </div>

                                   <div className="col-lg-6">
                                        <div className="__img-sec" >
                                             <div className="__tree-img">
                                                  <img src={leavesTree} alt='icon' />
                                             </div>
                                             <img src={logo_3d} alt={title + '-logo'} style={{ transform: `translate(${offsetX}px, ${offsetY}px) scale(0.8)` }} />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </main>
          </div>
     );
};

export default FailedRegister;


