import React, { useEffect, useState } from 'react'
import { api_url, bnbFee, receivingAddress, TGWContractAddress, titleDescription } from '../../config/Config';
import uploadIcon from '../../Assets/Image/uploadIcon.png';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { useTranslation } from 'react-i18next';
import Loader from '../../config/Loader';
import { Bookmark, FullscreenSharp } from '@mui/icons-material';
import Modal from 'react-responsive-modal';
import SupportTab from '../../Private/SupportTab';
import { useWeb3Con } from '../../config/Web3ContextProvider';
import Web3Set from '../../config/Web3Set';
import { Link } from 'react-router-dom';

const Transfer = (props) => {
     const [web3Details, setweb3Details] = useState({ web3: null, address: '' })

     const { t } = useTranslation();
     const { WalletAddress, web3data } = useWeb3Con();
     const [tokenContract, settokenContract] = useState();
     const [web3, setweb3] = useState();
     const [TGWContract, setTGWContract] = useState();
     const [activeLogin, setactiveLogin] = useState(false);

     const [approveLoader, setapproveLoader] = useState('0');
     const [changeSection, setchangeSection] = useState(false);
     useEffect(() => {
          document.title = `${props.pageTitle} | ${titleDescription}`;
          userDataFound()
          getCSRF()
          getFundBalance()
     }, []);

     const [csrfToken, setCsrfToken] = useState('');

     const getCSRF = async () => {
          await fetch(`${api_url}security/csrf-token`, { credentials: 'include' }) // Ensure cookies are sent
               .then(response => response.json())
               .then(data => setCsrfToken(data.csrfToken));

     }
     const [balanceCheck, setbalanceCheck] = useState(0);

     const getFundBalance = async () => {
          const data1 = await _fetch(`${api_url}activation/checkFundBalance`, "GET", {});
          if (data1.status === 'success') {
               setbalanceCheck(data1?.balance);
               // console.log(data1?.data?.data);
          }
     }
     const [userDetails, setuserdetails] = useState({

          username: "",
          phone: "",
          sponsor_id: "",
          name: "",
          wallet_address: null,
          active_status: false,
          disable: false,
          withdraw_status: true,
          package: 0,

     });
     const userDataFound = async () => {

          const data1 = await _fetch(`${api_url}user/userInfo`, "GET", {});
          if (data1.status === 'success') {

               setuserdetails(data1?.data?.data);

               console.log(data1?.data?.data);

          } else {
               // localStorage.clear();
               // navigate("/login");
          }
     }

     const [userid, setuserid] = useState();
     const [amount, setamount] = useState();


     const [btnDisable, setbtnDisable] = useState(false);

     const [loading, setLoading] = useState(false);
     const handleError = (error) => {
          setapproveLoader(0);

          if (error.code === 4001) {
               toasted.error("User denied transaction signature.");
          } else {
               let msg = error.message.split(":");
               toasted.error("Error " + error.code + " : " + (msg[1] ? msg[1] : error.message));
          }
     };


     // const approveAndSendTokens = async (e) => {
     //      e.preventDefault()

     //      // if (loading) return;
     //      setLoading(true);
     //      if (userDetails?.wallet_address?.toLowerCase() == WalletAddress?.toLowerCase()) {
     //           if (amount >= 30) {
     //                setapproveLoader(1)
     //                const web3 = web3Details?.web3;
     //                const account = web3Details?.address;
     //                // const tokenContract = web3Details?.tokenContract;
     //                // const TGWContract = web3Details?.TGWContract;
     //                const TGWContract = web3Details?.TGWContract;


     //                if (web3 && account && tokenContract && TGWContract) {
     //                     const amountInBNB = bnbFee;
     //                     const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
     //                     let gasPrice2 = await web3.eth.getGasPrice();
     //                     const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));

     //                     const bnbBalances = await web3.eth.getBalance(account);
     //                     const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

     //                     const chainIds = await web3.eth.getChainId();
     //                     const chainId = parseInt(chainIds.toString().replace("n", ""));
     //                     console.log('chainId', chainId);

     //                     if (chainId != 56) {
     //                          setLoading(false);
     //                          setapproveLoader(0)

     //                          return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
     //                     }

     //                     if (bnbBalance <= amountInWei) {
     //                          setLoading(false);
     //                          setapproveLoader(0)

     //                          return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
     //                     }

     //                     // if (web3Details.tokenBalance < amount) {
     //                     //      setLoading(false);
     //                     //      setapproveLoader(0)

     //                     //      return toasted.error(`Your USDT balance is insufficient. Please add more ${amount - (web3Details.tokenBalance)} USDT to proceed!`);
     //                     // }


     //                     // const nonce = await web3.eth.getTransactionCount(account, 'pending');
     //                     console.log('allapprovalAmountdddsdsdcwsd');

     //                     try {



     //                          const approvalAmount = web3.utils.toWei((amount), 'ether');

     //                          const allapprovalAmount = web3.utils.toWei(amount, 'ether');
     //                          try {

     //                               const gasPriceMain = await web3.eth.getGasPrice();
     //                               const gasEstimateMain = await tokenContract.methods.approve(TGWContractAddress, allapprovalAmount).estimateGas({ from: account });
     //                               const gasEstimateMain2 = parseInt(gasEstimateMain.toString().replace("n", ""));

     //                               const nonceR = await web3.eth.getTransactionCount(account, 'pending');
     //                               const approvalClear = await tokenContract.methods.approve(TGWContractAddress, allapprovalAmount).send({
     //                                    from: account,
     //                                    gasPrice: gasPrice * 1.3,
     //                                    gasLimit: gasEstimateMain2,
     //                                    nonce: nonceR
     //                               })
     //                                    .on('transactionHash', async function (hash) {
     //                                         setapproveLoader('2')
     //                                         setchangeSection(false)
     //                                         setLoading(false);
     //                                    })
     //                                    .on('receipt', async function (receipt) {
     //                                         const amounts = parseInt(approvalAmount);
     //                                         const getEstimatedGas = await TGWContract.methods.buyTokens(approvalAmount.toString()).estimateGas({ from: account });
     //                                         const estimatedGas2 = parseInt(getEstimatedGas.toString().replace("n", ""));
     //                                         const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

     //                                         await TGWContract.methods.buyTokens(approvalAmount.toString()).send({
     //                                              from: account,
     //                                              gasPrice: gasPrice * 1.3,
     //                                              gasLimit: estimatedGas2,
     //                                              nonce: nonce2
     //                                         })
     //                                              .on('transactionHash', function (hash) {
     //                                                   setchangeSection(true)
     //                                                   setLoading(false);
     //                                              })
     //                                              .on('error', function (error) {
     //                                                   setLoading(false);
     //                                                   setapproveLoader(0);
     //                                                   handleError(error);
     //                                              })
     //                                              .then(async function (receipt) {
     //                                                   SubmitForm(receipt.transactionHash);
     //                                                   setactiveLogin(true);
     //                                                   // setGethash(receipt.transactionHash);
     //                                                   setLoading(false);
     //                                              });
     //                                    })
     //                                    .on('error', function (error) {
     //                                         setLoading(false);
     //                                         setapproveLoader(0);
     //                                         handleError(error);
     //                                    });

     //                          } catch (error) {
     //                               setLoading(false);
     //                               setapproveLoader(0)
     //                               handleError(error);
     //                          }


     //                     } catch (error) {
     //                          setLoading(false);
     //                          setapproveLoader(0)
     //                          handleError(error);
     //                     }

     //                }
     //           } else {
     //                setLoading(false);
     //                setapproveLoader(0);
     //                return toasted.error('Minimum Amount 30USDT!');

     //           }
     //      } else {
     //           setLoading(false);
     //           setapproveLoader(0);
     //           return toasted.error('Please verify and try again with the correct wallet.!');
     //      }
     // };
     // // const [randomLetter, setRandomLetter] = useState('');

     // const generateRandomAlphabet = () => {
     //      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
     //      const randomIndex = Math.floor(Math.random() * alphabet.length);
     //      return (alphabet[randomIndex]);
     // };


     // const approveAndSendTokens = async (e) => {
     //      e.preventDefault()

     //      // if (loading) return;
     //      console.log('web3Details', web3Details);
     //      setLoading(true);
     //      if (amount >= 30) {
     //           setapproveLoader(1)
     //           const web3 = web3Details?.web3;
     //           const account = web3Details?.address;
     //           // const tokenContract = web3Details?.tokenContract;
     //           // const TGWContract = web3Details?.TGWContract;
     //           const TGWContract = web3Details?.TGWContract;
     //           console.log('TGWContract', TGWContract);

     //           console.log('web3', web3, 'account', account, 'tokenContract', tokenContract, 'TGWContract', TGWContract);

     //           if (web3 && account && tokenContract && TGWContract) {
     //                const amountInBNB = bnbFee;
     //                const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
     //                let gasPrice2 = await web3.eth.getGasPrice();
     //                const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));

     //                const bnbBalances = await web3.eth.getBalance(account);
     //                const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

     //                const chainIds = await web3.eth.getChainId();
     //                const chainId = parseInt(chainIds.toString().replace("n", ""));
     //                console.log('chainId', chainId);

     //                if (chainId != 56) {
     //                     setLoading(false);
     //                     setapproveLoader(0)

     //                     return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
     //                }

     //                if (bnbBalance <= amountInWei) {
     //                     setLoading(false);
     //                     setapproveLoader(0)

     //                     return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
     //                }

     //                // if (web3Details.tokenBalance < amount) {
     //                //      setLoading(false);
     //                //      setapproveLoader(0)

     //                //      return toasted.error(`Your USDT balance is insufficient. Please add more ${amount - (web3Details.tokenBalance)} USDT to proceed!`);
     //                // }


     //                // const nonce = await web3.eth.getTransactionCount(account, 'pending');
     //                console.log('allapprovalAmountdddsdsdcwsd');

     //                try {



     //                     const approvalAmount = web3.utils.toWei((amount), 'ether');
     //                     console.log('approvalAmount', approvalAmount);

     //                     const allapprovalAmount = web3.utils.toWei(amount, 'ether');
     //                     console.log('allapprovalAmount', allapprovalAmount);
     //                     try {

     //                          const gasPriceMain = await web3.eth.getGasPrice();
     //                          const gasEstimateMain = await tokenContract.methods.approve(receivingAddress, allapprovalAmount).estimateGas({ from: account });
     //                          const gasEstimateMain2 = parseInt(gasEstimateMain.toString().replace("n", ""));

     //                          const nonceR = await web3.eth.getTransactionCount(account, 'pending');
     //                          const approvalClear = await tokenContract.methods.approve(receivingAddress, allapprovalAmount).send({
     //                               from: account,
     //                               gasPrice: gasPrice * 1.3,
     //                               gasLimit: gasEstimateMain2,
     //                               nonce: nonceR
     //                          })
     //                               .on('transactionHash', async function (hash) {
     //                                    setapproveLoader('2')
     //                                    setchangeSection(false)
     //                                    setLoading(false);
     //                               })
     //                               .on('receipt', async function (receipt) {
     //                                    const amounts = parseInt(approvalAmount);
     //                                    const getEstimatedGas = await tokenContract.methods.transfer(receivingAddress,approvalAmount.toString()).estimateGas({ from: account });
     //                                    const estimatedGas2 = parseInt(getEstimatedGas.toString().replace("n", ""));
     //                                    const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

     //                                    await tokenContract.methods.transfer(receivingAddress,approvalAmount.toString()).send({
     //                                         from: account,
     //                                         gasPrice: gasPrice * 1.3,
     //                                         gasLimit: estimatedGas2,
     //                                         nonce: nonce2
     //                                    })
     //                                         .on('transactionHash', function (hash) {
     //                                              setchangeSection(true)
     //                                              setLoading(false);
     //                                         })
     //                                         .on('error', function (error) {
     //                                              setLoading(false);
     //                                              setapproveLoader(0);
     //                                              handleError(error);
     //                                         })
     //                                         .then(async function (receipt) {
     //                                              SubmitForm(receipt.transactionHash);
     //                                              setactiveLogin(true);
     //                                              // setGethash(receipt.transactionHash);
     //                                              setLoading(false);
     //                                         });
     //                               })
     //                               .on('error', function (error) {
     //                                    setLoading(false);
     //                                    setapproveLoader(0);
     //                                    handleError(error);
     //                               });

     //                     } catch (error) {
     //                          setLoading(false);
     //                          setapproveLoader(0)
     //                          handleError(error);
     //                     }


     //                } catch (error) {
     //                     setLoading(false);
     //                     setapproveLoader(0)
     //                     handleError(error);
     //                }

     //           }
     //      } else {
     //           setLoading(false);
     //           setapproveLoader(0);

     //           return toasted.error('Minimum Amount 30USDT!');

     //      }
     // };

     const [otp, setotp] = useState();


     const SubmitForm = async (e) => {
          e.preventDefault();
          // setapproveLoader(0);
          // hash.preventDefault()
          if (balanceCheck > 0) {
               setbtnDisable(true);

               let data = {
                    "userid": userid,
                    "amount": amount,
                    'otp': otp


               }


               let res = await _fetch(`${api_url}fund/fund-transfer`, "newPost", data, csrfToken)
               if (res?.status === "success") {
                    toasted.success(res?.message);
                    setbtnDisable(false);
                    window.location.reload();


               } else {
                    setbtnDisable(false);
                    toasted.error(res?.message);
                    getCSRF()
               }
          } else {
               setbtnDisable(false);
               toasted.error('Insufficient fund balance!');
               getCSRF()
          }
     }


     const [usdtBalance, setusdtBalance] = useState(0);



     return (
          <main>


               <div className="support_main">
                    <div className="support_inner mb-4">
                         <div className='card-header'>
                              <p> Transfer</p>
                              <h5>Your Balane is: {balanceCheck?.toFixed(4)} USDT</h5>
                         </div>
                         {/* <h3 className='text-danger'>Important Notice !!!</h3>
                         <p className='mb-0'>New Registration/Upgrade and Withdrawal will be off  From 12:00 PM To 3:00 PM </p>
                         <p>The inconvenience caused is deeply regretted.</p> */}


                         {/* <Web3Set stateprop={setweb3Details} setusdtBalance={setusdtBalance} setweb3={setweb3} setTGWContract={setTGWContract} settokenContract={settokenContract} /> */}


                         <div className="support-section">
                              <form onSubmit={SubmitForm}>

                                   <div className="select_reason mb-3">
                                        <label className='form_lable' htmlFor=''> User ID</label>
                                        <input type="text" placeholder='Enter User ID' value={userid} onChange={(e) => { setuserid(e.target.value) }} />
                                   </div>

                                   <div className="select_reason mb-3">
                                        <label className='form_lable' htmlFor=''> Amount</label>
                                        <input type="number" placeholder='Enter Amount' value={amount} onChange={(e) => { setamount(e.target.value) }} />
                                   </div>
                                   <div className="select_reason mb-3">
                                        <label className='form_lable' htmlFor=''> Pass Code</label>
                                        <input type="password" placeholder='Enter Pass Code' onChange={(e) => { setotp(e.target.value) }} value={otp} />
                                   </div>
                                   <div className="btn-sec">
                                        {balanceCheck > 0 ?
                                             <button className='new_button_css_dashboard' type='submit' disabled={btnDisable}>
                                                  {btnDisable == true ? 'Please Wait!' : 'Transfer Now'}
                                             </button>
                                             :
                                             <button className='new_button_css_dashboard' disabled>
                                                  Insufficient fund balance
                                             </button>
                                        }

                                        {/* <p className='mt-4'>Transaction failed.  <Link to={'/failed-upgrade'} >Click here to retry</Link>  or get assistance.</p> */}

                                   </div>
                              </form>


                         </div>
                    </div>
               </div>



          </main>
     )
}

export default Transfer