import React, { useEffect } from 'react';
import { titleDescription } from '../../config/Config';
import icon_insta from '../../Assets/new-theme/insta.png'
import icon_fb from '../../Assets/new-theme/facebook.png'
import icon_x from '../../Assets/new-theme/twitter.png'
// import icon_telegram from '../../Assets/new-theme/telegram.png'
import icon_linkdin from '../../Assets/new-theme/linkdin.png'

import { Link } from 'react-router-dom';
import { Launch, Verified } from '@mui/icons-material';

const SocialMedia = (props: any) => {

     useEffect(() => {
          document.title = `${props.pageTitle} | ${titleDescription}`;
     }, []);


     const MediaLinks = [
          {
               icon: icon_insta,
               title: 'Instagram',
               description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
               url: 'https://www.instagram.com/thegreenworldcommunity?igsh=aGNodjh3NTd6dmFz',
               verified: false,
          },
          {
               icon: icon_fb,
               title: 'Facebook',
               description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
               url: 'https://www.facebook.com/profile.php?id=61564525963913',
               verified: false,
          },
          {
               icon: icon_x,
               title: 'Twitter / X',
               description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
               url: 'https://x.com/GreenTgw97676',
               verified: false,
          },
          {
               icon: icon_linkdin,
               title: 'Linkdin',
               description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
               url: 'https://www.linkedin.com/company/tgw-the-green-world/',
               verified: false,
          },
     ];


     return (
          <main>
               <section className='__media'>
                    <div className='row'>
                         <div className='col-12'>
                              <div className='__heading_sa'>
                                   <p> Social Media Links</p>
                              </div>
                         </div>
                         {
                              MediaLinks.map((item, index) => (
                                   <div className='col-lg-4 col-md-6' key={index}>
                                        <div className='card'>
                                             <Link to="#" className="stretched-link"> </Link>
                                             <div className='card-icon'>
                                                  <img src={item.icon} alt='icon' />
                                             </div>

                                             <div className='card-desction'>
                                                  <h3> {item.title}</h3>
                                                  <p> {item.description}</p>
                                                  {
                                                       item.verified ?
                                                            <button type='button' className='btn verified-btn'>Link Verified  </button>
                                                            :
                                                            <Link to={item.url} className="btn" target='_blank'> Open Now </Link>
                                                  }

                                             </div>

                                             <div className='__verifie'>
                                                  {
                                                       item.verified ?
                                                            <Verified className='verified-icon' />
                                                            :
                                                            <Launch className='link-icon' />
                                                  }
                                             </div>
                                        </div>
                                   </div>
                              ))
                         }

                    </div>
               </section>
          </main>
     );
}

export default SocialMedia;
