import { ArrowDropDownCircle, East, Info, West } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { api_url, title, titleDescription } from '../../../config/Config';
import __activePerson from '../../../Assets/new-theme/active.png';
// import __activePerson from '../../../Assets/new-theme/inactive.png';
import __addNew from '../../../Assets/new-theme/add-new.png';
import { Link, useParams } from 'react-router-dom';
import _fetch from '../../../config/api';

const BinaryTree = (props: any) => {

    const [accordion, setAccordion] = useState(false);
    const { username } = useParams();



    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
        getAllTeamTree()
    }, [username]);


    const [teamLe1, setteamLe1] = useState({ level: 1, l1: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l2: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null } });
    const [teamLe2, setteamLe2] = useState({ level: 2, l21: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l22: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l23: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l24: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null } });
    const [teamLe3, setteamLe3] = useState({ level: 3, l31: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l32: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l33: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l34: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l35: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l36: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l37: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null }, l38: { leftBusiness: 0, leftTeam: 0, leftUser: null, rightBusiness: 0, rightTeam: 0, rightUser: null, selfPacakge: 0, totalpackage: 0, username: null, sponsor: null } });
    const [userInfoTopID, setuserInfoTopID] = useState({left_count: 0, left_node : 0,left_power: 0,package: 0, right_count :0,right_node:0, right_power : 0,sponsor_id :  null,total_package: 0,username:  null });




    const getAllTeamTree = async () => {
        // setLoading(true);
        // if (levelSearch != "" || downloadID != "") {
        //   var searchData = `&search=${searchedType}&value=${levelSearch}&downline_id=${downloadID}`;
        // } else {
        //   var searchData = "";
        // }
        let data = await _fetch(`${api_url}network/GenelogyTree?username=${username}`, 'GET', {})

        if (data?.status === 'success') {
            // console.log('data?.data?.[0]', data?.data);

            setteamLe1(data?.data?.[0]);
            setteamLe2(data?.data?.[1]);
            setteamLe3(data?.data?.[2]);
            setuserInfoTopID(data?.userinfo)

            //   setLoading(false);
        }
    }

    const [search, setsearch] = useState()
    return (
        <div className='binarytree-sec'>
            <div className='row'>
                <div className='col-12'>
                    <div className={accordion ? '__heading_sa show' : '__heading_sa'}>
                        <div className='__top-heading'>
                            <p> Team  Tree Structure</p>

                            <form>
                                {/* <select className="form-select">
                                    <option>Search Type</option>
                                    <option>Search By Username</option>
                                    <option>Search By Name</option>
                                </select> */}

                                <div className="input-group">
                                    <input type="search" className="form-control" placeholder="Search" onChange={(e: any) => { setsearch(e.target.value) }} value={search} />
                                </div>
                                <Link to={`/team-tree/${search}`} className='btn btn-success'> Search</Link>

                                {/* <button type='button' onClick={() => setAccordion(!accordion)} className='btn'> <ArrowDropDownCircle /></button> */}
                            </form>
                        </div>

                        {/* <div className='__bottom-heading'>
                            <div className='details-1'>
                                <West/>
                                <p>Left Total Team : <span>0</span></p>
                                <p>Left ZS : <span>0</span>	</p>
                                <p>Left Business : $<span>0</span></p>
                            </div>

                            <div className='details-1'>
                                <East/>
                                <p>Right Total Team :<span>0</span></p>
                                <p>Right ZS : <span>0</span>	</p>
                                <p>Right Business : $<span>0</span></p>
                            </div>

                        </div> */}
                    </div>
                </div>

                <div className='col-12'>
                    <section className="management-tree">
                        <div className="mgt-container">
                            <div className="mgt-wrapper">

                                <div className="mgt-item">

                                    <div className="mgt-item-parent">

                                        <div className="person">
                                            <img src={__activePerson} alt='icon' />
                                            <p className="name admin-name">{username}    <br />         {title}</p>

                                            <div className='__drophover'>
                                                <ul>
                                                    <li> <p>Username: {userInfoTopID?.username}</p> </li>
                                                    <li> <p> Sponsor ID: {userInfoTopID?.sponsor_id}</p> </li>
                                                    <li> <p> Package: {userInfoTopID?.total_package}</p> </li>

                                                    <li> <p>Left team : {userInfoTopID?.left_count} || Right team : {userInfoTopID?.right_count}</p> </li>
                                                  
                                                    <li>
                                                        <p>Left Business : ${userInfoTopID?.left_power?.toFixed(2)} | Right Business : ${userInfoTopID?.right_power?.toFixed(2)}</p>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="mgt-item-children">

                                        <div className="mgt-item-child">
                                            <div className="mgt-item">

                                                <div className="mgt-item-parent">
                                                    {teamLe1?.l1 ? <div className="person activePerson">
                                                        <Link to={`/team-tree/${teamLe1?.l1?.username}`}>
                                                            <img src={__activePerson} alt="icon" />
                                                        </Link>
                                                        <p className="name">{teamLe1?.l1?.username} <br />   {title}</p>

                                                        <div className='__drophover'>
                                                            <ul>
                                                                <li> <p>Username: {teamLe1?.l1?.username}</p> </li>
                                                                <li> <p> Sponsor ID: {teamLe1?.l1?.sponsor}</p> </li>
                                                                <li> <p>Total Package: ${teamLe1?.l1?.totalpackage}</p> </li>
                                                                <li> <p>Left team : {teamLe1?.l1?.leftTeam} || Right team : {teamLe1?.l1?.rightTeam}</p> </li>
                                                                <li>
                                                                    <p>Left Business : ${teamLe1?.l1?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe1?.l1?.rightBusiness?.toFixed(2)}</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div> :
                                                        <div className="person add-new">
                                                            <img src={__addNew} alt="icon" />

                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                        </div>
                                                    }

                                                </div>

                                                <div className="mgt-item-children">

                                                    <div className="mgt-item-child">

                                                        <div className='mgt-item-parent'>
                                                            {teamLe2?.l21 ? <div className="person">
                                                                <Link to={`/team-tree/${teamLe2?.l21?.username}`}>
                                                                    <img src={__activePerson} alt="icon" />
                                                                </Link>

                                                                <p className="name">{teamLe2?.l21?.username} <br /> {title}</p>

                                                                <div className='__drophover'>
                                                                    <ul>
                                                                        <li> <p>Username: {teamLe2?.l21?.username}</p> </li>
                                                                        <li> <p> Sponsor ID: {teamLe2?.l21?.sponsor}</p> </li>
                                                                        <li> <p>Total Package: ${teamLe2?.l21?.totalpackage}</p> </li>

                                                                        <li> <p>Left team : {teamLe2?.l21?.leftTeam} || Right team : {teamLe2?.l21?.rightTeam}</p> </li>

                                                                        <li>
                                                                            <p>Left Business : ${teamLe2?.l21?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe2?.l21?.rightBusiness?.toFixed(2)}</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div> :
                                                                <div className="person add-new">
                                                                    <img src={__addNew} alt="icon" />

                                                                    <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="mgt-item-children">

                                                            <div className="mgt-item-child">
                                                                {teamLe3?.l31 ? <div className="person">
                                                                    <Link to={`/team-tree/${teamLe3?.l31?.username}`}>
                                                                        <img src={__activePerson} alt="icon" />
                                                                    </Link>
                                                                    <p className="name">{teamLe3?.l31?.username} <br />  {title}</p>

                                                                    <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: {teamLe3?.l31?.username}</p> </li>
                                                                            <li> <p> Sponsor ID: {teamLe3?.l31?.sponsor}</p> </li>
                                                                            <li> <p>Total Package: ${teamLe3?.l31?.totalpackage}</p> </li>

                                                                            <li> <p>Left team : {teamLe3?.l31?.leftTeam} || Right team : {teamLe3?.l31?.rightTeam}</p> </li>

                                                                            <li>
                                                                                <p>Left Business : ${teamLe3?.l31?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe3?.l31?.rightBusiness?.toFixed(2)}</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                            <div className="mgt-item-child">
                                                                {teamLe3?.l32 ?
                                                                    <div className="person">
                                                                        <Link to={`/team-tree/${teamLe3?.l32?.username}`}>
                                                                            <img src={__activePerson} alt="icon" />
                                                                        </Link>
                                                                        <p className="name">{teamLe3?.l32?.username} <br /> {title}</p>

                                                                        <div className='__drophover'>
                                                                            <ul>
                                                                                <li> <p>Username: {teamLe3?.l32?.username}</p> </li>
                                                                                <li> <p> Sponsor ID: {teamLe3?.l32?.sponsor}</p> </li>
                                                                                <li> <p>Total Package: ${teamLe3?.l32?.totalpackage}</p> </li>

                                                                                <li> <p>Left team : {teamLe3?.l32?.leftTeam} || Right team : {teamLe3?.l32?.rightTeam}</p> </li>

                                                                                <li>
                                                                                    <p>Left Business : ${teamLe3?.l32?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe3?.l32?.rightBusiness?.toFixed(2)}</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="mgt-item-child">
                                                        <div className="mgt-item">

                                                            <div className="mgt-item-parent">
                                                                {teamLe2?.l22 ? <div className="person">
                                                                    <Link to={`/team-tree/${teamLe2?.l22?.username}`}>
                                                                        <img src={__activePerson} alt="icon" />
                                                                    </Link>
                                                                    <p className="name">{teamLe2?.l22?.username} <br /> {title}</p>

                                                                    <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: {teamLe2?.l22?.username}</p> </li>
                                                                            <li> <p> Sponsor ID: {teamLe2?.l22?.sponsor}</p> </li>
                                                                            <li> <p>Total Package: ${teamLe2?.l22?.totalpackage}</p> </li>

                                                                            <li> <p>Left team : {teamLe2?.l22?.leftTeam} || Right team : {teamLe2?.l22?.rightTeam}</p> </li>

                                                                            <li>
                                                                                <p>Left Business : ${teamLe2?.l22?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe2?.l22?.rightBusiness?.toFixed(2)}</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                            <div className="mgt-item-children">

                                                                <div className="mgt-item-child">
                                                                    {teamLe3?.l33 ? <div className="person">
                                                                        <Link to={`/team-tree/${teamLe3?.l33?.username}`}>
                                                                            <img src={__activePerson} alt="icon" />
                                                                        </Link>
                                                                        <p className="name">{teamLe3?.l33?.username} <br /> {title}</p>

                                                                        <div className='__drophover'>
                                                                            <ul>
                                                                                <li> <p>Username: {teamLe3?.l33?.username}</p> </li>
                                                                                <li> <p> Sponsor ID: {teamLe3?.l33?.sponsor}</p> </li>
                                                                                <li> <p>Total Package: ${teamLe3?.l33?.totalpackage}</p> </li>

                                                                                <li> <p>Left team : {teamLe3?.l33?.leftTeam} || Right team : {teamLe3?.l33?.rightTeam}</p> </li>

                                                                                <li>
                                                                                    <p>Left Business : ${teamLe3?.l33?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe3?.l33?.rightBusiness?.toFixed(2)}</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                        :

                                                                        <div className="person add-new">
                                                                            <img src={__addNew} alt="icon" />

                                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                        </div>}
                                                                </div>

                                                                <div className="mgt-item-child">
                                                                    {teamLe3?.l34 ? <div className="person">
                                                                        <Link to={`/team-tree/${teamLe3?.l34?.username}`}>
                                                                            <img src={__activePerson} alt="icon" />
                                                                        </Link>
                                                                        <p className="name">{teamLe3?.l34?.username} <br /> {title}</p>

                                                                        <div className='__drophover'>
                                                                            <ul>
                                                                                <li> <p>Username: {teamLe3?.l34?.username}</p> </li>
                                                                                <li> <p> Sponsor ID: {teamLe3?.l34?.sponsor}</p> </li>
                                                                                <li> <p>Total Package: ${teamLe3?.l34?.totalpackage}</p> </li>

                                                                                <li> <p>Left team : {teamLe3?.l34?.leftTeam} || Right team : {teamLe3?.l34?.rightTeam}</p> </li>

                                                                                <li>
                                                                                    <p>Left Business : ${teamLe3?.l34?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe3?.l34?.rightBusiness?.toFixed(2)}</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                        :

                                                                        <div className="person add-new">
                                                                            <img src={__addNew} alt="icon" />

                                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                        </div>}
                                                                </div>



                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>


                                        <div className="mgt-item-child">
                                            <div className="mgt-item">

                                                <div className="mgt-item-parent">
                                                    {teamLe1?.l2 ? <div className="person">
                                                        <Link to={`/team-tree/${teamLe1?.l2?.username}`}>
                                                            <img src={__activePerson} alt="" />
                                                        </Link>
                                                        <p className="name"> {teamLe1?.l2?.username} <br />     {title}</p>

                                                        <div className='__drophover'>
                                                            <ul>
                                                                <li> <p>Username: {teamLe1?.l2?.username}</p> </li>
                                                                <li> <p> Sponsor ID: {teamLe1?.l2?.sponsor}</p> </li>
                                                                <li> <p>Total Package: ${teamLe1?.l2?.totalpackage}</p> </li>

                                                                <li> <p>Left team :  {teamLe1?.l2?.leftTeam} || Right team :  {teamLe1?.l2?.rightTeam}</p> </li>

                                                                <li>
                                                                    <p>Left Business : ${teamLe1?.l2?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe1?.l2?.rightBusiness?.toFixed(2)}</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div> :

                                                        <div className="person add-new">
                                                            <img src={__addNew} alt="icon" />

                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                        </div>}
                                                </div>

                                                <div className="mgt-item-children">

                                                    <div className="mgt-item-child">

                                                        <div className='mgt-item-parent'>
                                                            {teamLe2?.l23 ? <div className="person">
                                                                <Link to={`/team-tree/${teamLe2?.l23?.username}`}>
                                                                    <img src={__activePerson} alt="icon" />
                                                                </Link>
                                                                <p className="name">{teamLe2?.l23?.username} <br /> {title} </p>

                                                                <div className='__drophover'>
                                                                    <ul>
                                                                        <li> <p>Username: {teamLe2?.l23?.username}</p> </li>
                                                                        <li> <p> Sponsor ID: {teamLe2?.l23?.sponsor}</p> </li>
                                                                        <li> <p>Total Package: ${teamLe2?.l23?.totalpackage}</p> </li>

                                                                        <li> <p>Left team : {teamLe2?.l23?.leftTeam} || Right team : {teamLe2?.l23?.rightTeam}</p> </li>

                                                                        <li>
                                                                            <p>Left Business : ${teamLe2?.l23?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe2?.l23?.rightBusiness?.toFixed(2)}</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div> :

                                                                <div className="person add-new">
                                                                    <img src={__addNew} alt="icon" />

                                                                    <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                </div>}
                                                        </div>

                                                        <div className="mgt-item-children">

                                                            <div className="mgt-item-child">
                                                                {teamLe3?.l35 ? <div className="person">
                                                                    <Link to={`/team-tree/${teamLe3?.l35?.username}`}>
                                                                        <img src={__activePerson} alt="icon" />
                                                                    </Link>
                                                                    <p className="name">{teamLe3?.l35?.username} <br /> {title}</p>

                                                                    <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: {teamLe3?.l35?.username}</p> </li>
                                                                            <li> <p> Sponsor ID: {teamLe3?.l35?.sponsor}</p> </li>
                                                                            <li> <p>Total Package: ${teamLe3?.l35?.totalpackage}</p> </li>

                                                                            <li> <p>Left team : {teamLe3?.l35?.leftTeam} || Right team : {teamLe3?.l35?.rightTeam}</p> </li>

                                                                            <li>
                                                                                <p>Left Business : ${teamLe3?.l35?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe3?.l35?.rightBusiness?.toFixed(2)}</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>



                                                            <div className="mgt-item-child">
                                                                {teamLe3?.l36 ? <div className="person">
                                                                    <Link to={`/team-tree/${teamLe3?.l36?.username}`}>
                                                                        <img src={__activePerson} alt="icon" />
                                                                    </Link>
                                                                    <p className="name">{teamLe3?.l36?.username} <br /> {title}</p>

                                                                    <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: {teamLe3?.l36?.username}</p> </li>
                                                                            <li> <p> Sponsor ID: {teamLe3?.l36?.sponsor}</p> </li>
                                                                            <li> <p>Total Package: ${teamLe3?.l36?.totalpackage}</p> </li>

                                                                            <li> <p>Left team : {teamLe3?.l36?.leftTeam} || Right team : {teamLe3?.l36?.rightTeam}</p> </li>

                                                                            <li>
                                                                                <p>Left Business : ${teamLe3?.l36?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe3?.l36?.rightBusiness?.toFixed(2)}</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="mgt-item-child">
                                                        <div className="mgt-item">

                                                            <div className="mgt-item-parent">

                                                                {teamLe2?.l24 ? <div className="person">
                                                                    <Link to={`/team-tree/${teamLe2?.l24?.username}`}>
                                                                        <img src={__activePerson} alt="icon" />
                                                                    </Link>
                                                                    <p className="name">{teamLe2?.l24?.username} <br />{title}</p>

                                                                    <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: {teamLe2?.l24?.username}</p> </li>
                                                                            <li> <p> Sponsor ID: {teamLe2?.l24?.sponsor}</p> </li>
                                                                            <li> <p>Total Package: ${teamLe2?.l24?.totalpackage}</p> </li>

                                                                            <li> <p>Left team : {teamLe2?.l24?.leftTeam} || Right team : {teamLe2?.l24?.rightTeam}</p> </li>

                                                                            <li>
                                                                                <p>Left Business : ${teamLe2?.l24?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe2?.l24?.rightBusiness?.toFixed(2)}</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                            <div className="mgt-item-children">

                                                                <div className="mgt-item-child">


                                                                    {teamLe3?.l37 ? <div className="person">
                                                                        <Link to={`/team-tree/${teamLe3?.l37?.username}`}>
                                                                            <img src={__activePerson} alt="icon" />
                                                                        </Link>
                                                                        <p className="name">{teamLe3?.l37?.username} <br /> {title}</p>

                                                                        <div className='__drophover'>
                                                                            <ul>
                                                                                <li> <p>Username: {teamLe3?.l37?.username}</p> </li>
                                                                                <li> <p> Sponsor ID: {teamLe3?.l37?.sponsor}</p> </li>
                                                                                <li> <p>Total Package: ${teamLe3?.l37?.totalpackage}</p> </li>

                                                                                <li> <p>Left team : {teamLe3?.l37?.leftTeam} || Right team : {teamLe3?.l37?.rightTeam}</p> </li>

                                                                                <li>
                                                                                    <p>Left Business : ${teamLe3?.l37?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe3?.l37?.rightBusiness?.toFixed(2)}</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div> :

                                                                        <div className="person add-new">
                                                                            <img src={__addNew} alt="icon" />

                                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                        </div>}
                                                                </div>

                                                                <div className="mgt-item-child">
                                                                    {teamLe3?.l38 ? <div className="person">
                                                                        <Link to={`/team-tree/${teamLe3?.l38?.username}`}>
                                                                            <img src={__activePerson} alt="icon" />
                                                                        </Link>
                                                                        <p className="name">{teamLe3?.l38?.username} <br /> {title}</p>

                                                                        <div className='__drophover'>
                                                                            <ul>
                                                                                <li> <p>Username: {teamLe3?.l38?.username}</p> </li>
                                                                                <li> <p> Sponsor ID: {teamLe3?.l38?.sponsor}</p> </li>
                                                                                <li> <p>Total Package: ${teamLe3?.l38?.totalpackage}</p> </li>

                                                                                <li> <p>Left team : {teamLe3?.l38?.leftTeam} || Right team : {teamLe3?.l38?.rightTeam}</p> </li>

                                                                                <li>
                                                                                    <p>Left Business : ${teamLe3?.l38?.leftBusiness?.toFixed(2)} | Right Business : ${teamLe3?.l38?.rightBusiness?.toFixed(2)}</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                        :

                                                                        <div className="person add-new">
                                                                            <img src={__addNew} alt="icon" />

                                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                        </div>}
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>



        </div>
    );
}

export default BinaryTree;
