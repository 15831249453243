import React from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { shortTitle, TGWTokenAddress, title } from '../../../config/Config';
import { Link } from 'react-router-dom';

const Banner = () => {

     const auth = localStorage.getItem('auth');

     return (
          <>

               <Parallax speed={-10}>
                    <div className='__g-banner'>
                         <div className='__ga-outer'>
                              <div className='__center'>
                                   <Parallax speed={-5}>
                                        <h6>{title}</h6>
                                        <h1>Where Crypto Meets Nature</h1>
                                        <p>Sign up now and start earning more</p>
                                        {
                                             auth ?
                                                  <Link to='/dashboard' className='btn mt-2'> Get Start</Link>
                                                  :
                                                  <div className="input_field">
                                                       <input type="text" placeholder='Enter Your Wallet Address' value={'0x80C260cd80d8a2855b554A7d5001BD06421a6fd4'} disabled />
                                                       <Link to={`https://bscscan.com/address/0x80C260cd80d8a2855b554A7d5001BD06421a6fd4#code`} target='_blank'>TGW Contract</Link>
                                                  </div>
                                        }

                                   </Parallax>
                              </div>
                         </div>

                    </div>

               </Parallax>
          </>
     );
}

export default Banner;
